import React, { useState, useEffect } from 'react';
import './MyReports.css';
import { ChevronDown, ChevronUp, Loader } from 'lucide-react';

// Helper function to generate HTML for D3 visualization
function generateHtmlFile(treeData, labelsMap) {
  const html = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Solana Transaction Tree</title>
    <script src="https://d3js.org/d3.v7.min.js"></script>
    <style>
      body, html { 
        margin: 0; 
        padding: 0; 
        height: 100%; 
        font-family: 'Arial', sans-serif; 
        background-color: #000; /* Black background */
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Updated font family */
      }
      #tree-container {
        width: 100%;
        height: 100vh;
        overflow: hidden;
      }
      .node circle {
        fill: #fff; /* White nodes */
        stroke: #bbb; /* Light gray stroke */
        stroke-width: 2px;
        transition: all 0.3s ease;
      }
      .node text {
        font: 12px sans-serif;
        fill: #fff; /* White text */
      }
      .node:hover circle {
        fill: #bbb; /* Light gray on hover */
      }
      .node:hover text {
        font-weight: bold;
      }
      .link {
        fill: none;
        stroke: #fff; /* White edges */
        stroke-width: 2px;
        stroke-opacity: 0.6;
      }
      .target-node circle {
        fill: #1e90ff; /* DodgerBlue for target nodes */
        stroke: #1e90ff;
      }
      .target-node text {
        fill: #1e90ff;
        font-weight: bold;
      }
      .label-text {
        font-weight: bold;
        fill: #aaa; /* Light gray labels */
      }
    </style>
  </head>
  <body>
    <div id="tree-container"></div>
    <script>
      const treeData = ${JSON.stringify(treeData)};
      const labelsMap = ${JSON.stringify(labelsMap)};

      const width = window.innerWidth;
      const height = window.innerHeight;
      const nodeWidth = 420;
      const nodeHeight = 100;

      const svg = d3.select("#tree-container")
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(50,0)");

      const tree = d3.tree().nodeSize([nodeHeight, nodeWidth]).separation((a, b) => (a.parent === b.parent ? 1 : 1.25));
      const root = d3.hierarchy(treeData, d => d.children || []);
      tree(root);

      const linkGenerator = d3.linkHorizontal().x(d => d.y).y(d => d.x);

      function update(source) {
        const nodes = root.descendants();
        const links = root.links();

        const node = svg.selectAll(".node")
          .data(nodes, d => d.data.address)
          .join("g")
          .attr("class", d => \`node \${d.data.isTarget ? "target-node" : ""}\`)
          .attr("transform", d => \`translate(\${d.y},\${d.x})\`);

        node.append("circle").attr("r", 6);
        
        // Display label and full wallet address
        node.append("text")
          .attr("dy", "-1em")
          .attr("x", d => d.children ? -8 : 8)
          .attr("text-anchor", d => d.children ? "end" : "start")
          .attr("class", "label-text")
          .text(d => labelsMap[d.data.address] || '');

        node.append("text")
          .attr("dy", "0.31em")
          .attr("x", d => d.children ? -8 : 8)
          .attr("text-anchor", d => d.children ? "end" : "start")
          .text(d => d.data.address);

        const link = svg.selectAll(".link")
          .data(links, d => d.target.data.address)
          .join("path")
          .attr("class", "link")
          .attr("d", linkGenerator);

        node.append("title")
          .text(d => \`Address: \${d.data.address}\\nLayer: \${d.data.layer}\\nFirst Inflow: \${d.data.firstInflow || 'N/A'}\`);

        const transition = svg.transition().duration(750);
        node.transition(transition).attr("transform", d => \`translate(\${d.y},\${d.x})\`);
        link.transition(transition).attr("d", linkGenerator);
      }

      update(root);

      const zoom = d3.zoom().scaleExtent([0.1, 3]).on("zoom", (event) => {
        svg.attr("transform", event.transform);
      });

      d3.select("svg").call(zoom);
    </script>
  </body>
  </html>
  `;

  return html; // Return the generated HTML content
}

const MyReports = () => {
  const [jobs, setJobs] = useState([]);
  const [expandedJob, setExpandedJob] = useState(null);
  const [jobHtml, setJobHtml] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [labels, setLabels] = useState({});

  useEffect(() => {
    const fetchCompletedJobs = async () => {
      try {
        const storedUser = JSON.parse(localStorage.getItem('discordUser'));
        const discordUserId = storedUser ? storedUser.id : null;

        if (!discordUserId) {
          console.error('No Discord user ID found.');
          return;
        }

        const response = await fetch(`https://api.pathfinder.cash/api/jobs/completed-jobs-by-user/${discordUserId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch jobs');
        }

        const data = await response.json();

        const completedJobs = data
          .filter((job) => job.status === 'completed')
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

        setJobs(completedJobs);

        const labelsResponse = await fetch(`https://api.pathfinder.cash/api/labels/get-labels/${discordUserId}`);
        if (!labelsResponse.ok) {
          throw new Error('Failed to fetch labels');
        }

        const labelsData = await labelsResponse.json();
        const labelsMap = labelsData.labels.reduce((map, userLabels) => {
          userLabels.labels.forEach(label => {
            map[label.address] = label.label;
          });
          return map;
        }, {});

        setLabels(labelsMap);

      } catch (error) {
        console.error('Error fetching jobs or labels:', error);
      }
    };

    fetchCompletedJobs();
  }, []);

  const handleToggleJobDetails = async (job) => {
    if (expandedJob === job._id) {
      setExpandedJob(null);
      return;
    }

    setExpandedJob(job._id);
    if (!jobHtml[job._id]) {
      setIsLoading(true);

      if (!job.result) {
        console.error('Missing result in job result');
        setIsLoading(false);
        return;
      }

      const htmlContent = generateHtmlFile(job.result, labels);
      setJobHtml((prev) => ({ ...prev, [job._id]: htmlContent }));
      setIsLoading(false);
    }
  };

  return (
    <div className="my-reports">
      <h2>My Reports</h2>
      {jobs.length === 0 ? (
        <p>No completed jobs found.</p>
      ) : (
        jobs.map((job) => (
          <div key={job._id} className={`job-card ${expandedJob === job._id ? 'expanded' : ''}`} style={{ backgroundColor: '#004080' }}>
            <div className="job-header" onClick={() => handleToggleJobDetails(job)}>
              <div>
                <span className="job-date">Completed: {new Date(job.updatedAt).toLocaleDateString()} - </span>
                <span className="job-id">Job ID: {job._id}</span>
              </div>
              {expandedJob === job._id ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>
            {expandedJob === job._id && (
              <div className="job-details">
                {isLoading ? (
                  <Loader className="loading-icon" size={24} />
                ) : (
                  jobHtml[job._id] ? (
                    <iframe
                      title={`Job Report ${job._id}`}
                      srcDoc={jobHtml[job._id]}
                      className="job-report-frame"
                    />
                  ) : (
                    <p>Error loading job details.</p>
                  )
                )}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default MyReports;