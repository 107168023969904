import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsAuthenticated, verifyDiscordRole, selectShouldRedirect, clearRedirectFlag } from '../slices/authSlice';

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const shouldRedirect = useSelector(selectShouldRedirect);
  const [isVerifying, setIsVerifying] = useState(true);

  useEffect(() => {
    const verifyAuth = async () => {
      if (localStorage.getItem('discordAccessToken')) {
        console.log('Verifying Discord role...');
        try {
          await dispatch(verifyDiscordRole()).unwrap();
        } catch (error) {
          console.error('Failed to verify Discord role:', error);
        }
      }
      setIsVerifying(false);
    };

    verifyAuth();
  }, [dispatch]);

  useEffect(() => {
    if (shouldRedirect) {
      console.log('User does not have required role, redirecting to homepage...');
      navigate('/');
      dispatch(clearRedirectFlag());
    }
  }, [shouldRedirect, navigate, dispatch]);

  if (isVerifying) {
    return <div>Verifying authentication...</div>;
  }

  if (!isAuthenticated) {
    console.log('User not authenticated, redirecting to login...');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  console.log('User authenticated and has required role, rendering protected content');
  return children;
};

export default ProtectedRoute;