import React from 'react';
import './Header.css'; // Use the new Navbar styles
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="navbar"> {/* Use navbar class to apply new styles */}
      {/* Text logo for branding */}
      <div className="navbar-brand">Pathfinder</div> {/* Text logo only */}
      {/* Login Button with Link */}
      <Link to="/login">
        <button className="login-button">Login</button>
      </Link>
    </header>
  );
};

export default Header;
