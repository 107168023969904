import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { User, PieChart, Tag, FileText, MoveRight, MoveLeft } from 'lucide-react';
import AnalyzePage from './AnalyzePage'; // Import the AnalyzePage component
import LabelsPage from './LabelsPage'; // Import the LabelsPage component
import MyReports from './MyReports';

const Dashboard = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState('Analyze'); // State to manage active tab
  const [username, setUsername] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    // Fetch user info from localStorage
    const storedUser = localStorage.getItem('discordUser');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setUsername(user.username);
      setAvatarUrl(`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`);
    }
  }, []);

  const handleToggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="dashboard">
      {/* Sidebar Navigation */}
      <nav className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
        {/* User Info Section */}
        <div className="user-info">
          <div className="user-avatar">
            {avatarUrl ? (
              <img src={avatarUrl} alt="User Avatar" className="avatar-img" />
            ) : (
              <User size={40} />
            )}
          </div>
          {!isCollapsed && (
            <div className="user-details">
              <h3>{username || 'Username'}</h3>
              <p>Online</p>
            </div>
          )}
        </div>

        {/* Menu Items */}
        <ul className="menu">
          <li className={`menu-item ${activeTab === 'Analyze' ? 'active' : ''}`} onClick={() => setActiveTab('Analyze')}>
            <PieChart size={20} />
            {!isCollapsed && <span>Analyze</span>}
          </li>
          <li className={`menu-item ${activeTab === 'Labels' ? 'active' : ''}`} onClick={() => setActiveTab('Labels')}>
            <Tag size={20} />
            {!isCollapsed && <span>Labels</span>}
          </li>
          <li className={`menu-item ${activeTab === 'My Reports' ? 'active' : ''}`} onClick={() => setActiveTab('My Reports')}>
            <FileText size={20} />
            {!isCollapsed && <span>My Reports</span>}
          </li>
        </ul>

        {/* Toggle Menu Button */}
        <button className="toggle-button" onClick={handleToggleMenu}>
          {isCollapsed ? <MoveRight color="#ffffff" /> : <MoveLeft color="#ffffff" />}
        </button>
      </nav>

      {/* Main Content Area */}
      <div className="main-content">
        {activeTab === 'Analyze' && <AnalyzePage />} {/* Show AnalyzePage on Analyze tab */}
        {activeTab === 'Labels' && <LabelsPage />} {/* Show LabelsPage on Labels tab */}
        {activeTab === 'My Reports' && <MyReports/>} {/* Placeholder for My Reports content */}
      </div>
    </div>
  );
};

export default Dashboard;
