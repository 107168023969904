import React, { useState, useEffect } from 'react';
import './AnalyzePage.css';
import { PlusCircle, CheckCircle, XCircle, Loader } from 'lucide-react';

const AnalyzePage = () => {
  // State management
  const [selectedModules, setSelectedModules] = useState(['Tree Analysis']);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [wallets, setWallets] = useState([{ address: '', locked: false }]);
  const [rootWalletAddress, setRootWalletAddress] = useState(''); // State for starting wallet
  const [apiKey, setApiKey] = useState(''); // State for the API Key
  const [maxLayers, setMaxLayers] = useState(1); // State for maxLayers
  const [jobs, setJobs] = useState([]); // State to hold the jobs
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission status

  const isStartDisabled =
    selectedModules.length === 0 ||
    selectedPlan === '' ||
    wallets.every((wallet) => wallet.address === '') ||
    rootWalletAddress === ''; // Disable Start if starting wallet is empty

    useEffect(() => {
      const fetchJobs = async () => {
        try {
          const storedUser = JSON.parse(localStorage.getItem('discordUser'));
          const discordUserId = storedUser ? storedUser.id : null;
    
          if (!discordUserId) {
            console.error('No Discord user ID found.');
            return;
          }
    
          const response = await fetch(`https://api.pathfinder.cash/api/jobs/by-user/${discordUserId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch jobs');
          }
    
          const data = await response.json();
          console.log('Fetched jobs:', data); // Inspect the fetched data
    
          // Normalize data to ensure each job has an 'id' field
          const normalizedData = data.map((job) => ({
            ...job,
            id: job._id, // Map _id to id
          }));
    
          // Update jobs while ensuring job IDs are maintained
          setJobs((prevJobs) => {
            const jobMap = new Map(prevJobs.map((job) => [job.id, job]));
    
            // Filter out completed jobs and update statuses
            const updatedJobs = normalizedData
              .filter((job) => job.status !== 'completed')
              .map((job) => {
                const existingJob = jobMap.get(job.id);
                return existingJob
                  ? { ...existingJob, status: job.status } // Update status
                  : job; // Add new job
              });
    
            return updatedJobs;
          });
        } catch (error) {
          console.error('Error fetching jobs:', error);
        }
      };
    
      fetchJobs();
      const interval = setInterval(fetchJobs, 30000); // Refresh every 30 seconds
    
      return () => clearInterval(interval); // Clean up the interval on component unmount
    }, []);
    
  

  // Handlers
  const toggleModule = (module) => {
    setSelectedModules((prev) =>
      prev.includes(module) ? prev.filter((m) => m !== module) : [...prev, module]
    );
  };

  const handlePlanChange = (plan) => {
    setSelectedPlan(plan);
  };

  const handleMaxLayersChange = (e) => {
    setMaxLayers(parseInt(e.target.value, 10)); // Update maxLayers state
  };

  const addWallet = () => {
    setWallets([...wallets, { address: '', locked: false }]);
  };

  const updateWallet = (index, value) => {
    const newWallets = [...wallets];
    newWallets[index].address = value;
    setWallets(newWallets);
  };

  const lockWallet = (index) => {
    const newWallets = [...wallets];
    newWallets[index].locked = !newWallets[index].locked;
    setWallets(newWallets);
  };

  const removeWallet = (index) => {
    setWallets(wallets.filter((_, i) => i !== index));
  };

  // Function to handle the Start button click
const handleStartClick = async () => {
  const requestBody = {
    rootWalletAddress,
    targetWallets: wallets.map((wallet) => wallet.address),
    maxLayers,
    apiKey,
    speed: selectedPlan.toLowerCase(), // Assuming the speed matches the plan name
    discordUserId: JSON.parse(localStorage.getItem('discordUser'))?.id, // Add Discord user ID
  };

  setIsSubmitting(true);

  try {
    const response = await fetch('https://api.pathfinder.cash/api/jobs/submit-job', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error('Failed to submit job');
    }

    const data = await response.json();
    console.log('Job submitted successfully:', data);

    // Use data._id as the job ID
    setJobs((prevJobs) => [
      ...prevJobs,
      { id: data._id, status: 'pending', parameters: requestBody },
    ]);

    setIsSubmitting(false); // Reset submission status
  } catch (error) {
    console.error('Error submitting job:', error);
    setIsSubmitting(false); // Reset submission status in case of error
  }
};


  return (
    <div className="analyze-page">
      {/* Starting Wallet Section */}
      <section className="starting-wallet">
        <h2>Starting Wallet</h2>
        <input
          type="text"
          placeholder="Enter Starting Wallet Address"
          value={rootWalletAddress}
          onChange={(e) => setRootWalletAddress(e.target.value)}
        />
      </section>

      {/* Modules Section */}
      <section className="modules">
        <h2>Modules</h2>
        <div>
          <input
            type="checkbox"
            id="tree-analysis"
            checked={selectedModules.includes('Tree Analysis')}
            onChange={() => toggleModule('Tree Analysis')}
          />
          <label htmlFor="tree-analysis">Tree Analysis</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="cluster-analysis"
            checked={selectedModules.includes('Cluster Analysis')}
            onChange={() => toggleModule('Cluster Analysis')}
          />
          <label htmlFor="cluster-analysis">Cluster Analysis</label>
        </div>
      </section>

      {/* Settings Section */}
      <section className="settings">
        <h2>Settings</h2>
        <div className="inline-field">
          <label htmlFor="api-key">Helius API Key</label>
          <input
            type="text"
            id="api-key"
            className="input-field-small"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
          />
        </div>


        <div className="radio-options">
          <div>
            <input
              type="radio"
              id="free"
              name="api-plan"
              checked={selectedPlan === 'Free'}
              onChange={() => handlePlanChange('Free')}
            />
            <label htmlFor="free">Free</label>
          </div>
          <div>
            <input
              type="radio"
              id="developer"
              name="api-plan"
              checked={selectedPlan === 'Developer'}
              onChange={() => handlePlanChange('Developer')}
            />
            <label htmlFor="developer">Developer</label>
          </div>
          <div>
            <input
              type="radio"
              id="business"
              name="api-plan"
              checked={selectedPlan === 'Business'}
              onChange={() => handlePlanChange('Business')}
            />
            <label htmlFor="business">Business</label>
          </div>
          <div>
            <input
              type="radio"
              id="professional"
              name="api-plan"
              checked={selectedPlan === 'Professional'}
              onChange={() => handlePlanChange('Professional')}
            />
            <label htmlFor="professional">Professional</label>
          </div>
        </div>

        {/* Max Layers Selection */}
        <div className="inline-field">
          <label htmlFor="max-layers">Max Layers</label>
          <select
            id="max-layers"
            value={maxLayers}
            onChange={handleMaxLayersChange}
            className="input-field-small"
          >
            {[1, 2, 3, 4, 5].map((layer) => (
              <option key={layer} value={layer}>
                {layer}
              </option>
            ))}
          </select>
        </div>
      </section>

      {/* Target Wallets Section */}
      <section className="target-wallets">
        <h2>Target Wallets</h2>
        {wallets.map((wallet, index) => (
          <div key={index} className="wallet">
            <input
              type="text"
              value={wallet.address}
              onChange={(e) => updateWallet(index, e.target.value)}
              disabled={wallet.locked}
              placeholder="Enter wallet address"
            />
            {!wallet.locked ? (
              <CheckCircle onClick={() => lockWallet(index)} className="icon" />
            ) : (
              <XCircle onClick={() => removeWallet(index)} className="icon" />
            )}
          </div>
        ))}
        <button onClick={addWallet} className="add-wallet-button">
          <PlusCircle size={24} /> Add Wallet
        </button>
      </section>

      {/* Start Button */}
      <button className="start-button" disabled={isStartDisabled || isSubmitting} onClick={handleStartClick}>
        Start
      </button>

      {/* My Tasks Section */}
      <section className="my-tasks">
        <h2>My Jobs</h2>
        {jobs.map((job) => (
          <div key={job.id} className={`task-card ${job.status}`}>
            {/* Always display the job ID */}
            <div className="job-id">Job ID: {job.id}</div>
            <div className="job-status">
              Status: {job.status}
              {(job.status === 'pending' || job.status === 'processing') && (
                <Loader className="loading-icon" size={20} />
              )}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default AnalyzePage;
