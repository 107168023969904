import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const verifyDiscordRole = createAsyncThunk(
  'auth/verifyDiscordRole',
  async (_, { getState }) => {
    const { auth } = getState();
    const response = await fetch('https://api.pathfinder.cash/api/check-discord-role', {
      headers: {
        'Authorization': `Bearer ${auth.accessToken}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to verify Discord role');
    }
    const data = await response.json();
    return { hasRequiredRole: data.hasRequiredRole, shouldRedirect: !data.hasRequiredRole };
  }
);

const loadAuthState = () => {
  try {
    const accessToken = localStorage.getItem('discordAccessToken');
    const user = JSON.parse(localStorage.getItem('discordUser'));
    if (accessToken && user) {
      console.log('Loaded auth state from localStorage');
      return { isAuthenticated: false, accessToken, user, roleVerified: false, shouldRedirect: false };
    }
  } catch (err) {
    console.error('Error loading auth state from localStorage:', err);
  }
  console.log('No valid auth state in localStorage');
  return { isAuthenticated: false, accessToken: null, user: null, roleVerified: false, shouldRedirect: false };
};

const initialState = loadAuthState();

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken } = action.payload;
      state.user = user;
      state.accessToken = accessToken;
      state.isAuthenticated = false; // Set to false until role is verified
      state.roleVerified = false;
      state.shouldRedirect = false;
      localStorage.setItem('discordAccessToken', accessToken);
      localStorage.setItem('discordUser', JSON.stringify(user));
      console.log('Auth credentials updated in Redux:', state);
    },
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      state.isAuthenticated = false;
      state.roleVerified = false;
      state.shouldRedirect = false;
      localStorage.removeItem('discordAccessToken');
      localStorage.removeItem('discordUser');
      console.log('User logged out, auth state cleared');
    },
    clearRedirectFlag: (state) => {
      state.shouldRedirect = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyDiscordRole.fulfilled, (state, action) => {
        state.roleVerified = true;
        state.isAuthenticated = action.payload.hasRequiredRole;
        state.shouldRedirect = action.payload.shouldRedirect;
        console.log('Discord role verified, auth state updated:', state);
      })
      .addCase(verifyDiscordRole.rejected, (state) => {
        state.roleVerified = false;
        state.isAuthenticated = false;
        state.shouldRedirect = true;
        console.log('Discord role verification failed');
      });
  },
});

export const { setCredentials, logout, clearRedirectFlag } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectIsAuthenticated = (state) => {
  console.log('Checking isAuthenticated:', state.auth.isAuthenticated && state.auth.roleVerified);
  return state.auth.isAuthenticated && state.auth.roleVerified;
};
export const selectShouldRedirect = (state) => state.auth.shouldRedirect;