// InfoSection.js
import React from 'react';
import { TreePine, Eye, Globe } from 'lucide-react';
import './InfoSection.css';

const InfoSection = () => {
  return (
    <section className="info-section" id="info-section">
      <video autoPlay muted loop className="info-video">
        <source src="https://res.cloudinary.com/dphcnbsie/video/upload/v1726759639/bg3_e6fw1u.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="info-title">
        <h2>Forensics <span className="highlight">Made Easy</span></h2>
        <p>Custom strategies and analysis to make wallet digging easier.</p>
      </div>
      <div className="info-cards">
        <div className="info-card">
          <div className="info-icon">
            <TreePine color="#ffffff" />
          </div>
          <h3>K-ary Tree Analysis</h3>
          <p>
            Our systems are carefully designed to analyze wallet connectivity on-chain.
          </p>
        </div>
        <div className="info-card">
          <div className="info-icon">
            <Eye color="#ffffff" />
          </div>
          <h3>Simple Visualization</h3>
          <p>
            View your wallet analysis in a simple and intuitive way.
          </p>
        </div>
        <div className="info-card">
          <div className="info-icon">
            <Globe color="#ffffff" />
          </div>
          <h3>Cluster Analysis</h3>
          <p>
            See where funds are moving and identify who is moving them.
          </p>
        </div>
      </div>
    </section>
  );
};

export default InfoSection;
