import React, { useState, useEffect } from 'react';
import './LabelsPage.css';
import { PlusCircle, CheckCircle, XCircle, Loader } from 'lucide-react'; // Import icons from lucide-react

const LabelsPage = () => {
  const [labels, setLabels] = useState([]); // State for labels
  const [isLoading, setIsLoading] = useState(false); // State for loading

  // Fetch labels from the server
  useEffect(() => {
    const fetchLabels = async () => {
      try {
        const storedUser = JSON.parse(localStorage.getItem('discordUser'));
        const discordUserId = storedUser ? storedUser.id : null;

        if (!discordUserId) {
          console.error('No Discord user ID found.');
          return;
        }

        setIsLoading(true);
        const response = await fetch(`https://api.pathfinder.cash/api/labels/get-labels/${discordUserId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch labels');
        }

        const data = await response.json();
        
        // Extract the nested labels from the response
        const extractedLabels = data.labels.length > 0 ? data.labels[0].labels : [];
        
        // Initialize existing labels with locked: true
        const initializedLabels = extractedLabels.map(label => ({ ...label, locked: true }));
        setLabels(initializedLabels);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching labels:', error);
        setIsLoading(false);
      }
    };

    fetchLabels();
  }, []);

  const addLabel = () => {
    setLabels([...labels, { label: '', address: '', locked: false }]);
  };

  const updateLabel = (index, field, value) => {
    const newLabels = [...labels];
    newLabels[index][field] = value;
    setLabels(newLabels);
  };

  const lockLabel = async (index) => {
    const newLabels = [...labels];
    newLabels[index].locked = !newLabels[index].locked;
    setLabels(newLabels);

    if (newLabels[index].locked) {
      // Save the new label to the server
      try {
        const storedUser = JSON.parse(localStorage.getItem('discordUser'));
        const discordUserId = storedUser ? storedUser.id : null;

        if (!discordUserId) {
          console.error('No Discord user ID found.');
          return;
        }

        const { label, address } = newLabels[index];
        const response = await fetch('https://api.pathfinder.cash/api/labels/add-label', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ discordUserId, label, address }),
        });

        if (!response.ok) {
          throw new Error('Failed to save label');
        }

        console.log('Label added successfully');
      } catch (error) {
        console.error('Error adding label:', error);
      }
    }
  };

  const removeLabel = async (index) => {
    try {
      const storedUser = JSON.parse(localStorage.getItem('discordUser'));
      const discordUserId = storedUser ? storedUser.id : null;

      if (!discordUserId) {
        console.error('No Discord user ID found.');
        return;
      }

      const { label, address } = labels[index];

      const response = await fetch('https://api.pathfinder.cash/api/labels/delete-label', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ discordUserId, label, address }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete label');
      }

      setLabels(labels.filter((_, i) => i !== index));
      console.log('Label removed successfully');
    } catch (error) {
      console.error('Error removing label:', error);
    }
  };

  return (
    <div className="labels-section">
      <h2>Labels</h2>
      {isLoading ? (
        <Loader size={24} />
      ) : (
        labels.map((entry, index) => (
          <div key={index} className="label-entry">
            <input
              type="text"
              value={entry.label}
              onChange={(e) => updateLabel(index, 'label', e.target.value)}
              disabled={entry.locked}
              placeholder="Enter label"
            />
            <input
              type="text"
              value={entry.address}
              onChange={(e) => updateLabel(index, 'address', e.target.value)}
              disabled={entry.locked}
              placeholder="Enter wallet address"
            />
            {!entry.locked ? (
              <CheckCircle onClick={() => lockLabel(index)} className="icon" />
            ) : (
              <XCircle onClick={() => removeLabel(index)} className="icon" />
            )}
          </div>
        ))
      )}
      <button onClick={addLabel} className="add-label-button">
        <PlusCircle size={24} /> Add Label
      </button>
    </div>
  );
};

export default LabelsPage;
