import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials, selectIsAuthenticated, verifyDiscordRole, selectShouldRedirect } from '../slices/authSlice';
import './LoginPage.css';

const LoginPage = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const shouldRedirect = useSelector(selectShouldRedirect);

  // Ref to ensure exchangeCodeForToken is called only once
  const hasExchangedCode = useRef(false);

  const exchangeCodeForToken = useCallback(async (code) => {
    try {
      const response = await fetch('https://api.pathfinder.cash/api/exchange-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.error === 'Invalid authorization code') {
          setError('Your login session has expired. Please try logging in again.');
          return;
        }
        throw new Error(`Failed to exchange code for token: ${JSON.stringify(errorData)}`);
      }

      const data = await response.json();
      dispatch(setCredentials({ user: data.user, accessToken: data.access_token }));
      localStorage.setItem('discordAccessToken', data.access_token);
      localStorage.setItem('discordUser', JSON.stringify(data.user));
      
      // Verify Discord role after setting credentials
      const roleVerificationResult = await dispatch(verifyDiscordRole()).unwrap();
      if (roleVerificationResult.hasRequiredRole) {
        navigate('/dashboard', { replace: true });
      } else {
        setError('You do not have the required role to access the dashboard.');
      }
    } catch (err) {
      console.error('Error in exchangeCodeForToken:', err);
      setError(err.message);
    }
  }, [navigate, dispatch]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code && !hasExchangedCode.current) {
      console.log('Code found in URL, exchanging for token...');
      hasExchangedCode.current = true; // Set ref to prevent multiple calls
      exchangeCodeForToken(code);
    }
  }, [exchangeCodeForToken]);

  const handleLogin = () => {
    const DISCORD_CLIENT_ID = "1284181103904821359";
    const DISCORD_REDIRECT_URI = "https://pathfinder.cash/login";
    const DISCORD_OAUTH_URL = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${encodeURIComponent(DISCORD_REDIRECT_URI)}&response_type=code&scope=identify%20email`;
   
    console.log('Redirecting to Discord OAuth:', DISCORD_REDIRECT_URI);
    window.location.href = DISCORD_OAUTH_URL;
  };

  // If user is already authenticated and has required role, redirect to dashboard
  useEffect(() => {
    if (isAuthenticated) {
      console.log('User is authenticated, redirecting to dashboard...');
      navigate('/dashboard', { replace: true });
    }
  }, [isAuthenticated, navigate]);

  // Handle redirection if user doesn't have required role
  useEffect(() => {
    if (shouldRedirect) {
      setError('You do not have the required role to access the dashboard.');
    }
  }, [shouldRedirect]);

  return (
    <div className="login-page">
      <div className="login-card">
        <img src="/img/logo.png" alt="Logo" className="login-logo" />
        <h1>Pathfinder</h1>
        <p>You must be a Verified Member in the Pathfinder Discord to Log In.</p>
        <button className="discord-button" onClick={handleLogin}>
          <img src="https://img.icons8.com/color/512/discord-logo.png" alt="Discord Icon" />
          Login with Discord
        </button>
        {error && <p className="error-message">Error: {error}</p>}
      </div>
    </div>
  );
};

export default LoginPage;