import React from 'react';
import './PremiumModulesSection.css';

const PremiumModulesSection = () => {
  return (
    <section className="premium-section">
      <div className="premium-title">
        <h2>
          Exclusive Access to <span className="highlight">Premium</span> Modules
        </h2>
        <p>
          Don't miss the next big influencer token, catch movements early.
        </p>
      </div>
      <div className="premium-modules">
        <div className="module">
          <div className="module-content">
            <h3>Dusting Wallet Filtering</h3>
            <p>
              Dusting wallets are a nusance, so why deal with digging through hundreds of transactions full of them? <br/>
            </p>
          </div>
        </div>
        <div className="module">
          <div className="module-content">
            <h3>Wallet Labeling</h3>
            <p>
              Easily visualize who is moving funds where in your graphs, add wallets you wish to be identified to your account and never miss them again.
            </p>
          </div>

        </div>
        <div className="module">
          <div className="module-content">
            <h3>Saved Reports</h3>
            <p>
              Think you missed something? No need to run it again, view your previous reports directly in your user panel.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PremiumModulesSection;
