import React from 'react';
import { ChevronDown } from 'lucide-react';
import './HeroSection.css';

const HeroSection = () => {
  const handleScrollDown = () => {
    const infoSection = document.getElementById('info-section');
    if (infoSection) {
      infoSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="hero">
      <video autoPlay muted loop playsInline className="hero-video">
        <source src="https://res.cloudinary.com/dphcnbsie/video/upload/v1726759639/bg2_v6cntb.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="hero-content">
        <img src="/img/logo.png" alt="Logo" className="hero-logo" />
        <h1 className="hero-title">
          <span className="highlight">Forensics</span> is <span className="highlight">difficult</span> on-chain
        </h1>
        <p className="hero-subtitle">Pathfinder makes it easy</p>
        <div className="hero-icon-button" onClick={handleScrollDown}>
          <ChevronDown size={32} />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;